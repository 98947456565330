import React, { useState } from 'react';
import './App.css';
import {
    SelectPokemon,
    SelectNature,
    SelectAbility,
    SelectPokeball,
    SelectGender,
    SelectItem,
    SelectLevel,
    IV,
    EV,
    SelectMove1,
    SelectMove2,
    SelectMove3,
    SelectMove4,
} from './SelectPokemon';
import { ShinyCheckBox, PokerusCheckBox } from './PokeCheckBoxes';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import pokeNature from './natures.jpg';
import { TextField } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function App() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [pokemon, setPokemon] = useState('');
    const [nature, setNature] = useState('');
    const [pokeball, setPokeball] = useState('Poke Ball');
    const [gender, setGender] = useState('Genderless');
    const [ability, setAbility] = useState('');
    const [level, setLevel] = useState('70');
    const [item, setItem] = useState('');
    const [isShiny, setIsShiny] = useState(false);
    const [hasPokerus, setHasPokerus] = useState(false);
    const [ivHp, setIvHp] = useState(31);
    const [ivAtk, setIvAtk] = useState(31);
    const [ivDef, setIvDef] = useState(31);
    const [ivSpa, setIvSpa] = useState(31);
    const [ivSpd, setIvSpd] = useState(31);
    const [ivSpe, setIvSpe] = useState(31);
    const [evHp, setEvHp] = useState(4);
    const [evAtk, setEvAtk] = useState(0);
    const [evDef, setEvDef] = useState(0);
    const [evSpa, setEvSpa] = useState(252);
    const [evSpd, setEvSpd] = useState(0);
    const [evSpe, setEvSpe] = useState(252);
    const [move1, setMove1] = useState('');
    const [move2, setMove2] = useState('');
    const [move3, setMove3] = useState('');
    const [move4, setMove4] = useState('');

    const whichPokemon = pokemon.charAt(0).toUpperCase() + pokemon.slice(1);
    const hasNature = nature ? `Nature: ${nature},` : '';
    const hasGender =
        gender === 'Genderless' || !gender ? '' : `Gender: ${gender},`;
    const hasAbility = ability ? `Ability: ${ability},` : '';
    const hasLevel = level ? `Level: ${level},` : '';
    const hasItem = item ? `Item: ${item},` : '';
    const shiny = isShiny ? 'Shiny: Yes,' : 'Shiny: No,';
    const pokerus = hasPokerus ? 'Pokerus: Yes,' : 'Pokerus: No,';

    const hasIvHp = ivHp === 31 || !ivHp ? '' : `IV_HP: ${ivHp},`;
    const hasIvAtk = ivAtk === 31 || !ivAtk ? '' : `IV_ATK: ${ivAtk},`;
    const hasIvDef = ivDef === 31 || !ivDef ? '' : `IV_DEF: ${ivDef},`;
    const hasIvSpa = ivSpa === 31 || !ivSpa ? '' : `IV_SPA: ${ivSpa},`;
    const hasIvSpd = ivSpd === 31 || !ivSpd ? '' : `IV_SPD: ${ivSpd},`;
    const hasIvSpe = ivSpe === 31 || !ivSpe ? '' : `IV_SPE: ${ivSpe}`;

    const hasEvHp = evHp === 0 || !evHp ? '' : `EV_HP: ${evHp},`;
    const hasEvAtk = evAtk === 0 || !evAtk ? '' : `EV_ATK: ${evAtk},`;
    const hasEvDef = evDef === 0 || !evDef ? '' : `EV_DEF: ${evDef},`;
    const hasEvSpa = evSpa === 0 || !evSpa ? '' : `EV_SPA: ${evSpa},`;
    const hasEvSpd = evSpd === 0 || !evSpd ? '' : `EV_SPD: ${evSpd},`;
    const hasEvSpe = evSpe === 0 || !evSpe ? '' : `EV_SPE: ${evSpe}`;

    const endl = !move1 ? '' : ',';

    const hasMove1 = move1 ? `Move1: ${move1},` : '';
    const hasMove2 = move2 ? `Move2: ${move2},` : '';
    const hasMove3 = move3 ? `Move3: ${move3},` : '';
    const hasMove4 = move4 ? `Move4: ${move4}` : '';

    const req = `!tradeBDSP Request: ${whichPokemon}, ${hasNature} Pokeball: ${pokeball}, ${hasGender} ${hasAbility} ${hasLevel} ${hasItem} ${shiny} ${pokerus} ${hasIvHp} ${hasIvAtk} ${hasIvDef} ${hasIvSpa} ${hasIvSpd} ${hasIvSpe} ${hasEvHp} ${hasEvAtk} ${hasEvDef} ${hasEvSpa} ${hasEvSpd} ${hasEvSpe}${endl} ${hasMove1} ${hasMove2} ${hasMove3} ${hasMove4}`;

    const submitHandler = async (event) => {
        event.preventDefault();
        console.log(req);
    };

    const copyText = (text) => {
        console.log(text);
        navigator.clipboard.writeText(text);
    };

    return (
        <Box component='form' onSubmit={submitHandler} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Item>
                        <SelectPokemon onSetPokemon={(p) => setPokemon(p)} />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <SelectNature onSetNature={(n) => setNature(n)} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectPokeball
                            pokeball={pokeball}
                            onSetPokeball={(b) => setPokeball(b)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectGender
                            gender={gender}
                            onSetGender={(g) => setGender(g)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <SelectAbility onSetAbility={(a) => setAbility(a)} />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <SelectItem onSetItem={(i) => setItem(i)} />
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <SelectLevel onSetLevel={(l) => setLevel(l)} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <ShinyCheckBox
                            isShiny={isShiny}
                            onSetShiny={(s) => setIsShiny(s)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <PokerusCheckBox
                            hasPokerus={hasPokerus}
                            onSetPokeRus={(r) => setHasPokerus(r)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <IV
                            onSetIvHp={(hp) => setIvHp(hp)}
                            onSetIvAtk={(atk) => setIvAtk(atk)}
                            onSetIvDef={(def) => setIvDef(def)}
                            onSetIvSpa={(spa) => setIvSpa(spa)}
                            onSetIvSpd={(spd) => setIvSpd(spd)}
                            onSetIvSpe={(spe) => setIvSpe(spe)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <EV
                            onSetEvHp={(hp) => setEvHp(hp)}
                            onSetEvAtk={(atk) => setEvAtk(atk)}
                            onSetEvDef={(def) => setEvDef(def)}
                            onSetEvSpa={(spa) => setEvSpa(spa)}
                            onSetEvSpd={(spd) => setEvSpd(spd)}
                            onSetEvSpe={(spe) => setEvSpe(spe)}
                        />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectMove1 onSetMove1={(m) => setMove1(m)} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectMove2 onSetMove2={(m) => setMove2(m)} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectMove3 onSetMove3={(m) => setMove3(m)} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SelectMove4 onSetMove4={(m) => setMove4(m)} />
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item sx={{ backgroundColor: '#507c36' }}>
                        <Link
                            sx={{ color: 'white' }}
                            href='https://serebii.net/brilliantdiamondshiningpearl/items.shtml'
                            target='_blank'>
                            Item List
                        </Link>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item sx={{ backgroundColor: '#507c36' }}>
                        <Link
                            sx={{ color: 'white' }}
                            href={`https://www.serebii.net/pokedex-swsh/${pokemon}`}
                            target='_blank'>
                            {pokemon
                                ? pokemon.charAt(0).toUpperCase() +
                                  pokemon.slice(1)
                                : 'Pokemon Info'}
                        </Link>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item sx={{ backgroundColor: '#507c36' }}>
                        <Link
                            sx={{ cursor: 'pointer', color: 'white' }}
                            onClick={handleOpen}>
                            Nature Info
                        </Link>
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item sx={{ backgroundColor: '#c2b592' }}>
                        <Link
                            sx={{ color: 'black' }}
                            href={`https://serebii.net/brilliantdiamondshiningpearl/ramanaspark.shtml`}
                            target='_blank'>
                            Ramanas Park
                        </Link>
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item sx={{ backgroundColor: '#6441a5' }}>
                        <Link
                            sx={{ color: 'white' }}
                            href={`https://www.twitch.tv/mitsuki_tv`}
                            target='_blank'>
                            Twitch
                        </Link>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <TextField
                            sx={{
                                backgroundColor: 'lightgray',
                                borderRadius: '8px',
                            }}
                            placeholder='Paste test area'
                            fullWidth
                            multiline
                            rows={3}
                            variant='outlined'
                        />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: '#5090D3',
                                borderRadius: '8px',
                                color: '#000',
                            }}
                            onClick={(e) => copyText(e.target.innerText)}>
                            {req}
                        </Typography>
                    </Item>
                </Grid>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <Box sx={style}>
                        <img src={pokeNature} alt='pokemon nature' />
                    </Box>
                </Modal>
            </Grid>
        </Box>
    );
}

export default App;

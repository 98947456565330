import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const ShinyCheckBox = (props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.isShiny}
                        onChange={(e) => props.onSetShiny(!props.isShiny)}
                    />
                }
                label='Shiny'
            />
        </FormGroup>
    );
};

export const PokerusCheckBox = (props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.hasPokerus}
                        onChange={(e) => props.onSetPokeRus(!props.hasPokerus)}
                    />
                }
                label='PokeRus'
            />
        </FormGroup>
    );
};

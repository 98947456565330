import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

export const SelectPokemon = (props) => {
    return (
        <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={allPokemon}
            fullWidth={true}
            renderInput={(params) => <TextField {...params} label='Pokemon' />}
            onSelect={(e) => props.onSetPokemon(e.target.value.toLowerCase())}
        />
    );
};

export const SelectNature = (props) => {
    return (
        <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={nature}
            fullWidth={true}
            renderInput={(params) => <TextField {...params} label='Nature' />}
            onSelect={(e) => props.onSetNature(e.target.value)}
        />
    );
};

export const SelectPokeball = (props) => {
    return (
        <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={pokeballs}
            fullWidth={true}
            value={props.pokeball}
            renderInput={(params) => <TextField {...params} label='Pokeball' />}
            onSelect={(e) => props.onSetPokeball(e.target.value)}
        />
    );
};

export const SelectGender = (props) => {
    return (
        <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={gender}
            fullWidth={true}
            value={props.gender}
            renderInput={(params) => <TextField {...params} label='Gender' />}
            onSelect={(e) => props.onSetGender(e.target.value)}
        />
    );
};

export const SelectAbility = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Ability'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetAbility(e.target.value)}
        />
    );
};

export const SelectItem = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Item'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetItem(e.target.value)}
        />
    );
};

export const SelectLevel = (props) => {
    return (
        <TextField
            id='filled-number'
            label='Level'
            type='number'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetLevel(e.target.value)}
        />
    );
};

export const IV = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <TextField
                    id='IV-HP'
                    label='IV-HP'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvHp(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='IV-Attack'
                    label='IV-Attack'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvAtk(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='IV-Defense'
                    label='IV-Defense'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvDef(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='IV-Special Attack'
                    label='IV-Special Attack'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvSpa(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='IV-Special Defense'
                    label='IV-Special Defense'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvSpd(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='IV-Speed'
                    label='IV-Speed'
                    type='number'
                    placeholder='0-31'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetIvSpe(e.target.value)}
                />
            </Grid>
        </Grid>
    );
};

export const EV = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <TextField
                    id='EV-HP'
                    label='EV-HP'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvHp(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='EV-Attack'
                    label='EV-Attack'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvAtk(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='EV-Defense'
                    label='EV-Defense'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvDef(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='EV-Special Attack'
                    label='EV-Special Attack'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvSpa(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='EV-Special Defense'
                    label='EV-Special Defense'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvSpd(e.target.value)}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id='EV-Speed'
                    label='EV-Speed'
                    type='number'
                    placeholder='0-252'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => props.onSetEvSpe(e.target.value)}
                />
            </Grid>
        </Grid>
    );
};

export const SelectMove1 = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Move-1'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetMove1(e.target.value)}
        />
    );
};

export const SelectMove2 = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Move-2'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetMove2(e.target.value)}
        />
    );
};

export const SelectMove3 = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Move-3'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetMove3(e.target.value)}
        />
    );
};

export const SelectMove4 = (props) => {
    return (
        <TextField
            id='filled-basic'
            label='Move-4'
            variant='filled'
            fullWidth={true}
            onBlur={(e) => props.onSetMove4(e.target.value)}
        />
    );
};

const allPokemon = [
    'Bulbasaur',
    'Ivysaur',
    'Venusaur',
    'Charmander',
    'Charmeleon',
    'Charizard',
    'Squirtle',
    'Wartortle',
    'Blastoise',
    'Caterpie',
    'Metapod',
    'Butterfree',
    'Weedle',
    'Kakuna',
    'Beedrill',
    'Pidgey',
    'Pidgeotto',
    'Pidgeot',
    'Rattata',
    'Raticate',
    'Spearow',
    'Fearow',
    'Ekans',
    'Arbok',
    'Pikachu',
    'Raichu',
    'Sandshrew',
    'Sandslash',
    'Nidoran♀',
    'Nidorina',
    'Nidoqueen',
    'Nidoran♂',
    'Nidorino',
    'Nidoking',
    'Clefairy',
    'Clefable',
    'Vulpix',
    'Ninetales',
    'Jigglypuff',
    'Wigglytuff',
    'Zubat',
    'Golbat',
    'Oddish',
    'Gloom',
    'Vileplume',
    'Paras',
    'Parasect',
    'Venonat',
    'Venomoth',
    'Diglett',
    'Dugtrio',
    'Meowth',
    'Persian',
    'Psyduck',
    'Golduck',
    'Mankey',
    'Primeape',
    'Growlithe',
    'Arcanine',
    'Poliwag',
    'Poliwhirl',
    'Poliwrath',
    'Abra',
    'Kadabra',
    'Alakazam',
    'Machop',
    'Machoke',
    'Machamp',
    'Bellsprout',
    'Weepinbell',
    'Victreebel',
    'Tentacool',
    'Tentacruel',
    'Geodude',
    'Graveler',
    'Golem',
    'Ponyta',
    'Rapidash',
    'Slowpoke',
    'Slowbro',
    'Magnemite',
    'Magneton',
    "Farfetch'd",
    'Doduo',
    'Dodrio',
    'Seel',
    'Dewgong',
    'Grimer',
    'Muk',
    'Shellder',
    'Cloyster',
    'Gastly',
    'Haunter',
    'Gengar',
    'Onix',
    'Drowzee',
    'Hypno',
    'Krabby',
    'Kingler',
    'Voltorb',
    'Electrode',
    'Exeggcute',
    'Exeggutor',
    'Cubone',
    'Marowak',
    'Hitmonlee',
    'Hitmonchan',
    'Lickitung',
    'Koffing',
    'Weezing',
    'Rhyhorn',
    'Rhydon',
    'Chansey',
    'Tangela',
    'Kangaskhan',
    'Horsea',
    'Seadra',
    'Goldeen',
    'Seaking',
    'Staryu',
    'Starmie',
    'Mr. Mime',
    'Scyther',
    'Jynx',
    'Electabuzz',
    'Magmar',
    'Pinsir',
    'Tauros',
    'Magikarp',
    'Gyarados',
    'Lapras',
    'Ditto',
    'Eevee',
    'Vaporeon',
    'Jolteon',
    'Flareon',
    'Porygon',
    'Omanyte',
    'Omastar',
    'Kabuto',
    'Kabutops',
    'Aerodactyl',
    'Snorlax',
    'Articuno',
    'Zapdos',
    'Moltres',
    'Dratini',
    'Dragonair',
    'Dragonite',
    'Mewtwo',
    'Mew',
    'Chikorita',
    'Bayleef',
    'Meganium',
    'Cyndaquil',
    'Quilava',
    'Typhlosion',
    'Totodile',
    'Croconaw',
    'Feraligatr',
    'Sentret',
    'Furret',
    'Hoothoot',
    'Noctowl',
    'Ledyba',
    'Ledian',
    'Spinarak',
    'Ariados',
    'Crobat',
    'Chinchou',
    'Lanturn',
    'Pichu',
    'Cleffa',
    'Igglybuff',
    'Togepi',
    'Togetic',
    'Natu',
    'Xatu',
    'Mareep',
    'Flaaffy',
    'Ampharos',
    'Bellossom',
    'Marill',
    'Azumarill',
    'Sudowoodo',
    'Politoed',
    'Hoppip',
    'Skiploom',
    'Jumpluff',
    'Aipom',
    'Sunkern',
    'Sunflora',
    'Yanma',
    'Wooper',
    'Quagsire',
    'Espeon',
    'Umbreon',
    'Murkrow',
    'Slowking',
    'Misdreavus',
    'Unown',
    'Wobbuffet',
    'Girafarig',
    'Pineco',
    'Forretress',
    'Dunsparce',
    'Gligar',
    'Steelix',
    'Snubbull',
    'Granbull',
    'Qwilfish',
    'Scizor',
    'Shuckle',
    'Heracross',
    'Sneasel',
    'Teddiursa',
    'Ursaring',
    'Slugma',
    'Magcargo',
    'Swinub',
    'Piloswine',
    'Corsola',
    'Remoraid',
    'Octillery',
    'Delibird',
    'Mantine',
    'Skarmory',
    'Houndour',
    'Houndoom',
    'Kingdra',
    'Phanpy',
    'Donphan',
    'Porygon2',
    'Stantler',
    'Smeargle',
    'Tyrogue',
    'Hitmontop',
    'Smoochum',
    'Elekid',
    'Magby',
    'Miltank',
    'Blissey',
    'Raikou',
    'Entei',
    'Suicune',
    'Larvitar',
    'Pupitar',
    'Tyranitar',
    'Lugia',
    'Ho-Oh',
    'Celebi',
    'Treecko',
    'Grovyle',
    'Sceptile',
    'Torchic',
    'Combusken',
    'Blaziken',
    'Mudkip',
    'Marshtomp',
    'Swampert',
    'Poochyena',
    'Mightyena',
    'Zigzagoon',
    'Linoone',
    'Wurmple',
    'Silcoon',
    'Beautifly',
    'Cascoon',
    'Dustox',
    'Lotad',
    'Lombre',
    'Ludicolo',
    'Seedot',
    'Nuzleaf',
    'Shiftry',
    'Taillow',
    'Swellow',
    'Wingull',
    'Pelipper',
    'Ralts',
    'Kirlia',
    'Gardevoir',
    'Surskit',
    'Masquerain',
    'Shroomish',
    'Breloom',
    'Slakoth',
    'Vigoroth',
    'Slaking',
    'Nincada',
    'Ninjask',
    'Shedinja',
    'Whismur',
    'Loudred',
    'Exploud',
    'Makuhita',
    'Hariyama',
    'Azurill',
    'Nosepass',
    'Skitty',
    'Delcatty',
    'Sableye',
    'Mawile',
    'Aron',
    'Lairon',
    'Aggron',
    'Meditite',
    'Medicham',
    'Electrike',
    'Manectric',
    'Plusle',
    'Minun',
    'Volbeat',
    'Illumise',
    'Roselia',
    'Gulpin',
    'Swalot',
    'Carvanha',
    'Sharpedo',
    'Wailmer',
    'Wailord',
    'Numel',
    'Camerupt',
    'Torkoal',
    'Spoink',
    'Grumpig',
    'Spinda',
    'Trapinch',
    'Vibrava',
    'Flygon',
    'Cacnea',
    'Cacturne',
    'Swablu',
    'Altaria',
    'Zangoose',
    'Seviper',
    'Lunatone',
    'Solrock',
    'Barboach',
    'Whiscash',
    'Corphish',
    'Crawdaunt',
    'Baltoy',
    'Claydol',
    'Lileep',
    'Cradily',
    'Anorith',
    'Armaldo',
    'Feebas',
    'Milotic',
    'Castform',
    'Kecleon',
    'Shuppet',
    'Banette',
    'Duskull',
    'Dusclops',
    'Tropius',
    'Chimecho',
    'Absol',
    'Wynaut',
    'Snorunt',
    'Glalie',
    'Spheal',
    'Sealeo',
    'Walrein',
    'Clamperl',
    'Huntail',
    'Gorebyss',
    'Relicanth',
    'Luvdisc',
    'Bagon',
    'Shelgon',
    'Salamence',
    'Beldum',
    'Metang',
    'Metagross',
    'Regirock',
    'Regice',
    'Registeel',
    'Latias',
    'Latios',
    'Kyogre',
    'Groudon',
    'Rayquaza',
    'Jirachi',
    'Deoxys',
    'Turtwig',
    'Grotle',
    'Torterra',
    'Chimchar',
    'Monferno',
    'Infernape',
    'Piplup',
    'Prinplup',
    'Empoleon',
    'Starly',
    'Staravia',
    'Staraptor',
    'Bidoof',
    'Bibarel',
    'Kricketot',
    'Kricketune',
    'Shinx',
    'Luxio',
    'Luxray',
    'Budew',
    'Roserade',
    'Cranidos',
    'Rampardos',
    'Shieldon',
    'Bastiodon',
    'Burmy',
    'Wormadam',
    'Mothim',
    'Combee',
    'Vespiquen',
    'Pachirisu',
    'Buizel',
    'Floatzel',
    'Cherubi',
    'Cherrim',
    'Shellos',
    'Gastrodon',
    'Ambipom',
    'Drifloon',
    'Drifblim',
    'Buneary',
    'Lopunny',
    'Mismagius',
    'Honchkrow',
    'Glameow',
    'Purugly',
    'Chingling',
    'Stunky',
    'Skuntank',
    'Bronzor',
    'Bronzong',
    'Bonsly',
    'Mime Jr.',
    'Happiny',
    'Chatot',
    'Spiritomb',
    'Gible',
    'Gabite',
    'Garchomp',
    'Munchlax',
    'Riolu',
    'Lucario',
    'Hippopotas',
    'Hippowdon',
    'Skorupi',
    'Drapion',
    'Croagunk',
    'Toxicroak',
    'Carnivine',
    'Finneon',
    'Lumineon',
    'Mantyke',
    'Snover',
    'Abomasnow',
    'Weavile',
    'Magnezone',
    'Lickilicky',
    'Rhyperior',
    'Tangrowth',
    'Electivire',
    'Magmortar',
    'Togekiss',
    'Yanmega',
    'Leafeon',
    'Glaceon',
    'Gliscor',
    'Mamoswine',
    'Porygon-Z',
    'Gallade',
    'Probopass',
    'Dusknoir',
    'Froslass',
    'Rotom',
    'Uxie',
    'Mesprit',
    'Azelf',
    'Dialga',
    'Palkia',
    'Heatran',
    'Regigigas',
    'Giratina',
    'Cresselia',
    'Phione',
    'Manaphy',
    'Darkrai',
    'Shaymin',
    'Arceus',
];

const nature = [
    'Adamant',
    'Bashful',
    'Bold',
    'Brave',
    'Calm',
    'Careful',
    'Docile',
    'Gentle',
    'Hardy',
    'Hasty',
    'Impish',
    'Jolly',
    'Lax',
    'Lonely',
    'Mild',
    'Modest',
    'Naive',
    'Naughty',
    'Quiet',
    'Quirky',
    'Rash',
    'Relaxed',
    'Sassy',
    'Serious',
    'Timid',
];

const gender = ['Male', 'Female', 'No Gender'];

const pokeballs = [
    'Poke Ball',
    'Premier Ball',
    'Great Ball',
    'Ultra Ball',
    'Quick Ball',
    'Nest Ball',
    'Net Ball',
    'Dive Ball',
    'Dusk Ball',
    'Heal Ball',
    'Luxury Ball',
    'Timer Ball',
    'Repeat Ball',
    'Master Ball',
];
